import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { z } from "zod";

export const useTheme = () => {
  const [cookies, setCookie] = useCookies(["theme"]);
  const theme =
    z
      .union([z.literal("dark"), z.literal("light")])
      .default("dark")
      .safeParse(cookies.theme).data ?? "dark";

  const setTheme = useCallback(
    (theme: "dark" | "light") => {
      setCookie("theme", theme, { path: "/" });
    },
    [setCookie],
  );

  return [theme, setTheme] as const;
};
